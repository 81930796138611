import React, { Component } from 'react';
import Menu from './Menu'
import Logo from './Logo'
import Mapa from './Mapa'
import Ubicacion from './Ubicacion'
import Calidad from './Calidad'
import Sectores from './Sectores'
import Infraestructura from './Infraestructura'
import Invertir from './Invertir'
import Personal from './Personal'
import Carousel from './Carousel'
import Pie from './Pie'

import Modal from 'react-bootstrap/Modal';

import { connect } from 'react-redux';
//import { useCountUp } from 'react-countup';
import { Element } from 'react-scroll';

import Es from './../redux/actionEs';
import In from './../redux/actionIn';

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

/*
const CompleteHook = () => {
  const { countUp } = useCountUp({
    start: 0,
    end: window.proyectos,
    delay: 2,
    duration: 10,
    //onReset: () => console.log('Resetted!'),
    //onUpdate: () => console.log('Updated!'),
    //onPauseResume: () => console.log('Paused or resumed!'),
    //onStart: ({ pauseResume }) => console.log(pauseResume),
    onEnd: ({ countUp, pauseResume, reset, start, update })  => {
      pauseResume()
      reset()
      start()
    },
  });
  return (
    <div>
      <div className="count_up">{countUp}</div>
      {/*
      <button onClick={start}>Start</button>
      <button onClick={reset}>Reset</button>
      <button onClick={pauseResume}>Pause/Resume</button>
      <button onClick={() => update(2000)}>Update to 2000</button>
      *//*}
    </div>
  );
};
*/

class Inicio extends Component {
  constructor() {
    super();
    this.state = {
      lenguaje: '',
      show: false,
    }
  }

  componentWillMount () {
    window.H5_loading.show();
  }
  
  componentDidMount () {
    window.H5_loading.hide();
  }

  cambiaIdioma (e) {
    window.H5_loading.show();
    this.setState({lenguaje: e})
    if(e==="IN"){
      localStorage.setItem('idioma', e)
      this.props.cambiaIn()
    }else{
      localStorage.removeItem('idioma')
      this.props.cambiaEs()
    }
    window.H5_loading.hide();
  }

  handleHide(){
    this.setState({ show: false });
  };

  render () {
    return (
      <div>
        <Menu cual={1} ver={this.state.lenguaje}/>
        <Logo cual={1}/>
        <div className="mensaje_inicial">
          <div className="max_width padding_inicial" data-aos="flip-up">
            {/*<h3>local storage: {localStorage.idioma} | state: {this.state.lenguaje} | redux: {this.props.lenguaje}<br/></h3>*/}
            {
              localStorage.idioma || this.state.lenguaje==="IN" ?
                <div>
                  <h16>HIDALGO:</h16><br/>
                  THE NEW ECONOMIC<br/>
                  POWERHOUSE OF<br/>
                  CENTRAL MEXICO
                </div>
              :
                <div>
                  <h16>HIDALGO:</h16><br/>
                  LA NUEVA POTENCIA<br/>
                  ECONÓMICA DEL<br/>
                  CENTRO DE MÉXICO
                </div>
            }
          </div>
        </div>
        <video autoplay="autoplay" loop="loop" className="video_background" preload="auto" muted>
          <source src="img/videos/intro.mp4" type="video/mp4" />
        </video>
        <div className="redes_inicio">
          <div className="max_width padding_redes_inicio">
            {
              window._my_redes.map((item, i) => 
                <a href={item.link} target="_new" className="de_flex_redes grises" key={i}><img src={item.imagen} alt={item.nombre} title={item.nombre}/></a>
              )
            }
            <div className="idiomasx" onClick={this.cambiaIdioma.bind(this, "IN")}>English</div>
            <div className="idiomasx" onClick={this.cambiaIdioma.bind(this, "ES")}>Español</div>
          </div>
        </div>

        <Element name="mapa"></Element>
        <Mapa/>
        <Carousel />

        <Element name="nueva"></Element>
        <div className="bg_azulSedeco padding_uno">
          <div className="max_width mapa_fondo at_c">
            {/*
              localStorage.idioma || this.state.lenguaje==="IN" ?
                <div>
                  <div data-aos="zoom-in">
                    <h5>NEW REALITY</h5>
                    <h5>IN HIDALGO</h5>
                  </div>
                  <br/>
                  MORE THAN <h16><h17>{window.inversionIn}</h17></h16> USD
                  <br/>
                  IN NEW INVESTMENTS
                  <br/>
                  <div data-aos="zoom-in">
                    <CompleteHook/>
                    <br/>
                    <h5>NEW</h5>
                    <h5>PROJECTS</h5>
                    <div id="cl"><br/></div>
                    <a href="https://datamexico.org/en/profile/geo/hidalgo-hg#economia" target="_new" className="grises"><img src="http://investhidalgo.mx/img/datahidalgo.svg" alt="DataMÉXICO" title="DataMÉXICO" height="100px"/></a>
                    <div id="cl"></div>
                  </div>
                </div> 
              :
                <div>
                  <div data-aos="zoom-in">
                    <h5>NUEVA REALIDAD</h5>
                    <h5>EN HIDALGO</h5>
                  </div>
                  <br/>
                  MÁS DE <h16><h17>{window.inversion}</h17></h16> DE PESOS
                  <br/>
                  EN NUEVAS INVERSIONES
                  <br/>
                  <div data-aos="zoom-in">
                    <CompleteHook/>
                    <br/>
                    <h5>PROYECTOS</h5>
                    <h5>CONSOLIDADOS</h5>
                    <div id="cl"><br/></div>
                    <a href="https://datamexico.org/es/profile/geo/hidalgo-hg#economia" target="_new" className="grises"><img src="http://investhidalgo.mx/img/datahidalgo.svg" alt="DataMÉXICO" title="DataMÉXICO" height="100px"/></a>
                    <div id="cl"></div>
                  </div>
                </div> 
            */}{/*
            <div className="spot">
              HIDALGO:<br/>
              THE<br/>
              MEXICAN<br/>
              SPOT
            </div>
            <div id="cl"></div>
            <div className="linea_spot"></div>
            <div id="cl"></div>
            */}
            <a href={localStorage.idioma || this.state.lenguaje==="IN" ? "https://www.economia.gob.mx/datamexico/en/profile/geo/hidalgo-hg" : "https://www.economia.gob.mx/datamexico/es/profile/geo/hidalgo-hg"} target="_new" className="grises"><img src="http://investhidalgo.mx/img/datahidalgo.svg" alt="DataMÉXICO" title="DataMÉXICO" height="100px"/></a>
          </div>
        </div>

        <Element name="ubicacion"></Element>
        <Ubicacion/>
        <Element name="calidad"></Element>
        <Calidad/>
        <Element name="sectores"></Element>
        <Sectores/>
        <Element name="industrial"></Element>
        <Infraestructura/>
        <Element name="invertir"></Element>
        <Invertir/>
{/*
        <Element name="ventanilla"></Element>
        <div className="fondo_ventanilla">
          <div data-aos="fade-up" className="de_fondo">
            <div className="max_width at_c padding">
              {
                localStorage.idioma || this.state.lenguaje==="IN" ?
                  <div>
                    <h16><h5>RA-FA</h5></h16>
                    <div className="texto_degra">
                      Digital tool that allows systematizing and constantly monitoring the attention of investors.
                    </div>
                    <a href={window.ventanilla} target="new"><img src="img/www.svg" title="ONE-STOP-SHOP" alt="ONE-STOP-SHOP" className="icono_ventanilla grises"/></a>
                  </div>
                :
                  <div>
                    <h16><h5>RA-FA</h5></h16>
                    <div className="texto_degra">
                      Herramienta digital que permite sistematizar y dar seguimiento constante en la atención a los y las inversionistas, administrando e interconectando la información y documentación con cada dependencia de gobierno en un sólo punto, garantizando así la agilidad de procesos y su transparencia.
                    </div>
                    <a href={window.ventanilla} target="new"><img src="img/www.svg" title="VENTANILLA ÚNICA" alt="VENTANILLA ÚNICA" className="icono_ventanilla grises"/></a>
                  </div>
              }
            </div>
          </div>
        </div>
*/}
        <Element name="somos"></Element>
        <Personal/>
        {/*<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d936.7266426843987!2d-98.77535777082973!3d20.096192058611532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1a1483f0088cd%3A0x7ca904b3622ca274!2sSecretar%C3%ADa+de+Desarrollo+Econ%C3%B3mico!5e0!3m2!1ses-419!2smx!4v1548192192357" className="mapa" frameBorder="0" style={{border:0,margin:0,padding:0}} allowfullscreen/>*/}



        <Modal
            show={this.state.show}
            onHide={this.handleHide.bind(this)}
            dialogClassName="modal-90w"
            className="modal_fer"
            aria-labelledby="example-custom-modal-styling-title">

            <Modal.Body className="modal_cuadro_veda" data-aos="fade-down">
              <div className="jh-close close-button" onClick={this.handleHide.bind(this)}>x</div>
              <h3>Aviso a la Ciudadanía en General</h3>
              <br/><br/>
              <p>Con fundamento en el Artículo 35, fracc. IX, y el Artículo 41, base III, Apartado C, párrafo segundo y 134 párrafo octavo de la Constitución Política de los Estados Unidos Mexicanos; Artículo 33, Ley Federal de Revocación de mandato; Artículo 209 número 1 de la Ley General de Instituciones y Procedimientos Electorales; Artículo 24, base II, párrafo noveno de la Constitución Política del Estado de Hidalgo y Artículo 126, párrafo tercero del Código Electoral del Estado de Hidalgo, se informa a la ciudadanía que algunas secciones de este portal de internet quedarán suspendidas durante el tiempo que comprenden el proceso de revocación de mandato y el proceso electoral ornidario de gubernatura 2021-2022, hasta la conclusión de la respectiva jornada comicial (del 4 de febrero, hasta las 00:00 horas del 5 de junio 2022)</p>
               {/*
               <br/><br/>
               <b>Ante la pandemia causada por el COVID-19, si presentas algún malestar o necesitas atención marca al 911.</b>
               <br/><br/>
               <em>*En razón del Proceso Electoral de Diputados y Diputadas Locales y Federales 2020-2021.</em>*/}
               <br/>
               <center>
                <img style={{width: "25%"}} src="https://cdn.hidalgo.gob.mx/escudo.png" alt="Veda electoral" title="Veda electoral"/>
               </center>
            </Modal.Body>
          </Modal>

        <div id="cl"></div>
        <Pie />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    lenguaje: state.lenguaje
  }
};
const mapDispatchToProps = (dispatch) => {
  return {
    cambiaEs: () => dispatch(Es()),
    cambiaIn: () => dispatch(In()),
  }
}

const Liga = Inicio;
export default connect(mapStateToProps,mapDispatchToProps)(Liga);