import React, { Component } from 'react';
//import { Link } from 'react-router-dom'

import Modal from 'react-bootstrap/Modal';

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

class Mapa extends Component {
  componentWillMount () {
    window.H5_loading.show();
  }
  
  componentDidMount () {
    window.H5_loading.hide();
  }

  state = {
    show: false,
    cual: "",
  }

  handleShow(variable){
    window.H5_loading.show();
    this.setState({
      show: true,
      cual: variable,
    });
    window.H5_loading.hide();
  };

  handleHide(){
    this.setState({ show: false });
  };

  render () {
      return (
        <div className="fondo_ubicacion">
          <div className="max_width padding_width at_c">
              
            <div className="degradados4 pa_degradados" data-aos="fade-right">
              <a href={localStorage.idioma ? "http://mapa.investhidalgo.mx/index.html" : "http://mapa.investhidalgo.mx/index.html?idioma=es"} target="new" className="texto_calidad">
                <h5>
                  {
                    localStorage.idioma ? 
                      "ECONOMIC MAP" 
                    : 
                      "MAPA ECONÓMICO"
                  }
                </h5>
              </a>
              <div id="cl"><br/></div>
              {localStorage.idioma ? "State, municipal and regional geo-referenced map." : "Mapa geo-referenciado del estado, municipal y regionalmente."}
              <div id="cl"><br/></div>
              <a href={localStorage.idioma ? "http://mapa.investhidalgo.mx/index.html" : "http://mapa.investhidalgo.mx/index.html?idioma=es"} target="new" className="texto_calidad">
                <h55>{localStorage.idioma ? "GO TO MAP" : "IR AL MAPA"}</h55>
              </a>
              <div id="cl"></div>
              <div className="ver_mas" onClick={this.handleShow.bind(this, "INTRO")}>+</div>
            </div>
            <div className="flexMapa">
              {
                window._my_mapaInformacion.length>0 ?
                  window._my_mapaInformacion.map((item, i) => {
                    let color = ""
                    if(i===1 || i===3){
                      color = "rgba(43, 45, 60, 0.6)"
                    }
                    return(
                      <div onClick={this.handleShow.bind(this, item.botonIn)} style={{backgroundColor: color}}>{localStorage.idioma ? item.botonIn : item.boton}</div>
                    )
                  })
                :
                  null
              }
            </div>
              
          </div>

          <Modal
            show={this.state.show}
            onHide={this.handleHide.bind(this)}
            dialogClassName="modal-90w"
            className="modal_fer"
            aria-labelledby="example-custom-modal-styling-title">

            <Modal.Body className="modal_cuadro" data-aos="fade-down">
              {
                this.state.cual==='INTRO' ?
                  <div className="c_infra">
                      <div className="cc_infra">
                        <div className="degradadosx padding_infra">
                          <h51>{localStorage.idioma ? "INTRODUCTION" : "INTRODUCCIÓN"}</h51>
                          <br/><br/>
                          <div dangerouslySetInnerHTML={{ __html: localStorage.idioma ? window._my_introduccionIn : window._my_introduccion }} className="texto_infra at_j"/>
                          <img src="img/h_naranja.svg" alt="Investh Hidalgo" className="invertir_icono"/>
                          <div id="cl"></div>
                          {/*<h21>HIDALGO: THE MEXICAN SPOT</h21>*/}
                        </div>
                      </div>
                    </div>
                :
                  window._my_mapaInformacion.filter(p => p.botonIn===this.state.cual).map((item, i) =>
                    <div className="c_infra">
                      <div className="cc_infra">
                        <div className="degradadosx padding_infra">
                          <img src={item.imagen} title={localStorage.idioma ? item.tituloIn : item.titulo} alt={localStorage.idioma ? item.tituloIn : item.titulo}/>
                          <div id="cl"></div>
                          <h51>{localStorage.idioma ? item.tituloIn : item.titulo}</h51>
                          <br/><br/>
                          <div dangerouslySetInnerHTML={{ __html: localStorage.idioma ? item.descripcionIn : item.descripcion }} className="texto_infra at_j"/>
                          <img src="img/h_naranja.svg" alt="Investh Hidalgo" className="invertir_icono"/>
                          <div id="cl"></div>
                          {/*<h21>HIDALGO: THE MEXICAN SPOT</h21>*/}
                        </div>
                      </div>
                    </div>
                  )
              }
            </Modal.Body>
          </Modal>
        </div>
      )
    
  }
}

export default Mapa;