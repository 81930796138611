import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Inicio from './components/Inicio'
import Cali from './components/Cali'
import Infra from './components/Infra'
import Contacto from './components/Contacto'
import Video from './components/Video'
import Mapa from './components/MapaFrame'
import TaiwanVisit from './components/TaiwanVisit'

import ReactGA from 'react-ga';
const TRACKING_ID = "UA-231287964-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);


class App extends Component {
  componentDidMount(){
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  componentDidUpdate() {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render () {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={Inicio} />
          <Route path={window.path+"/calidad"} component={Cali} />
          <Route path={window.path+"/infraestructura"} component={Infra} />
          <Route path={window.path+"/contacto"} component={Contacto} />
          <Route path={window.path+"/mapa"} component={Mapa} />
          <Route path={window.path+"/TaiwanVisit"} component={TaiwanVisit} />
          <Route path={window.path+"/video/:id"} component={Video} />
          <Route component={Inicio} />
        </Switch>
      </Router>
    )
  }
}

export default App;