import React, { Component } from 'react';

import Modal from 'react-bootstrap/Modal';

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

class Invertir extends Component {
  componentWillMount () {
    window.H5_loading.show();
  }
  
  componentDidMount () {
    window.H5_loading.hide();
  }
 
  state = {
    show: false,
    id: "",
  }

  handleShow(id, e){
    window.H5_loading.show();
    if(id==='RFI'){
      window.location="http://investhidalgo.mx/RFI/index.php"
    }else{
      this.setState({ show: true, id: id });
    }
    window.H5_loading.hide();
  };

  handleHide(){
    this.setState({ show: false });
  };

  render () {
      return (
        <div className="fondo_invertir">
          {
            window._my_invertir.length > 0 ?
              <div data-aos="fade-up" className="de_fondo">
                <div className="max_width at_c padding">
                  <h16><h5>{localStorage.idioma ? "INTERESTED IN INVESTING?" : "¿INTERESADO EN INVERTIR?"}</h5></h16>
                  <div className="texto_degra">
                    {
                      localStorage.idioma ? 
                        "Hidalgo is a turnkey industrial destination, which provides certainty in the development of any project, providing specific support for strategic sectors, and promoting the generation of new growth opportunities. " 
                      : 
                        "Hidalgo, es un destino industrial llave en mano, que permite brindar certidumbre en el desarrollo de cualquier proyecto, otorgando apoyos específicos para sectores estratégicos, promoviendo la generación de nuevas oportunidades de crecimiento."
                    }
                  </div>
                  <div className="flex_invertir">
                    {
                      window._my_invertir.map((item, i) => 
                        <div key={i} className="de_flex_invertir" id={item.tipo ? 'de_inver' : null}>
                          <div className="h_invertir" onClick={this.handleShow.bind(this, item.nombre)}>{localStorage.idioma ? item.nombreIn : item.nombre}</div>
                          <div id="cl"></div>
                          <div className="ver_mas" onClick={this.handleShow.bind(this, item.nombre)}>+</div>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
            :
              null
          }

          <Modal
            show={this.state.show}
            onHide={this.handleHide.bind(this)}
            dialogClassName="modal-90w"
            className="modal_fer"
            aria-labelledby="example-custom-modal-styling-title">

            <Modal.Body className="modal_cuadro cuadro_invertir" data-aos="fade-down">
              <div className="jh-close close-button" onClick={this.handleHide.bind(this)}>x</div>
              <img src="img/logo_investh.svg" alt="Investh Hidalgo" className="invertir_icono_top"/>
              <div id="cl"></div>
              {
                this.state.id ?
                  window._my_invertir.filter(p => p.nombre === this.state.id).slice(0, 1).map((item, i) =>  
                    <div className="invertir" data-aos="fade-right" key={i}>
                      {
                        !item.image ? 
                          <div className="descripcion_invertir">
                            <h100><h6>{localStorage.idioma ? item.nombreIn : item.nombre}</h6></h100>
                            <br/><br/>
                            <div className="texto_invertir" dangerouslySetInnerHTML={{ __html: localStorage.idioma ? item.descripcionIn : item.descripcion }} />
                            {
                              this.state.id==='Generación de talento' ? 
                                <div>
                                  <center>
                                  <a href="https://transformandoconlajuventud.hidalgo.gob.mx/" target="_new" className="grises"><img height="80" src="img/logo_miprimer.svg" title="Transformando con la Juventud" alt="Transformando con la Juventud"/></a>
                                  </center>
                                  <br/><br/>
                                </div>
                              :
                                null
                            }
                          </div>
                        :
                          <a href={localStorage.idioma ? item.imageIn : item.image} target="new"><img width="100%" src={localStorage.idioma ? item.imageIn : item.image} title={localStorage.idioma ? item.nombreIn : item.nombre} alt={localStorage.idioma ? item.nombreIn : item.nombre}/></a>
                      }
                    </div>
                  )
                :
                  null
              }
              <div id="cl"></div>
              {/*
              <div className="spot_invertir">
                <div className="spot">
                  HIDALGO:<br/>
                  THE<br/>
                  MEXICAN<br/>
                  SPOT
                </div>
                <div id="cl"></div>
                <div className="linea_spot"></div>
              </div>
              <div id="cl"></div>
              */}
            </Modal.Body>
          </Modal>
        </div>
      )
    
  }
}

export default Invertir;