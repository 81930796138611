import React, { Component } from 'react';

class Mapa extends Component {
  componentWillMount () {
    window.H5_loading.show();
  }
  
  componentDidMount () {
    window.H5_loading.hide();
  }

  render () {
      return (
        <iframe src="http://148.223.224.75/mapasedeco/carta" className="frame_mapaeconomico" title="Mapa Económico"/>
      )
    
  }
}

export default Mapa;