import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

class Calidad extends Component {
  componentWillMount () {
    window.H5_loading.show();
  }
  
  componentDidMount () {
    window.H5_loading.hide();
  }

  render () {
      return (
        <div className="fondo_calidad">
          <div className="max_width padding_width at_c">
              
            <div className="degradados3 pa_degradados" data-aos="fade-left">
              <Link to="calidad" className="texto_calidad_c">
                {
                  localStorage.idioma ? 
                    "HIDALGO IS AMONG THE 10 SAFEST AND MOST PEACEFUL STATES IN MÉXICO"
                  :
                    "HIDALGO, ENTRE LOS 10 ESTADOS MÁS PACÍFICOS DEL PAÍS"
                }
              </Link>
              <div id="cl"><br/></div>
              <Link to="calidad" className="texto_calidad">
                <h5>{localStorage.idioma ? "QUALITY OF LIFE" : "CALIDAD DE VIDA"}</h5>
              </Link>
              <div id="cl"></div>
              <Link to="calidad" className="sin"><div className="ver_mas">+</div></Link>
            </div>
              
          </div>
        </div>
      )
    
  }
}

export default Calidad;