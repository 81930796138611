import { createStore } from 'redux';
import reducerIdiomas from './reducerIdioma';

const store = createStore(reducerIdiomas);

export default store;
/*
import { createStore, combineReducers } from 'redux';

import reducerIdiomas from './reducerIdioma'
import reducerRutas from './reducerRuta'

const reducer = combineReducers ({
  reducerIdiomas,
  reducerRutas
});

const store = createStore(reducer);

export default store;
*/