import React, { Component } from 'react';
//import { Link } from 'react-router-dom'

import Modal from 'react-bootstrap/Modal';

import AOS from 'aos';
import 'aos/dist/aos.css';
import './taiwan.css';
AOS.init();

class TaiwanVisit extends Component {
  componentWillMount () {
    window.H5_loading.show();
  }
  
  componentDidMount () {
    window.H5_loading.hide();
  }

  state = {
    show: false,
    cual: "",
    lenguaje: 'IN',
  }

  handleShow(variable){
    window.H5_loading.show();
    this.setState({
      show: true,
      cual: variable,
    });
    window.H5_loading.hide();
  };

  handleHide(){
    this.setState({ show: false });
  };

  cambiaIdioma (e) {
    window.H5_loading.show();
    this.setState({lenguaje: e})
    window.H5_loading.hide();
  }

  render () {
      return (
        <div className="fondo_ubicacion de_taiwan" style={{backgroundImage: 'url(img/taiwan/fondo_taiwan.png)'}}>
          <div className="max_width padding_width_taiwan at_c">
            <img src="img/taiwan/taiwan.svg" title="SEDECO" alt="SEDECO" className="logo_hidalgo_taiwan"/>
            {/*
            <div id="cl"/>
            <div className="idiomasx" onClick={this.cambiaIdioma.bind(this, "IN")}>English</div>
            <div className="idiomasx" onClick={this.cambiaIdioma.bind(this, "ES")}>Español</div>
            */}
            {/*
            <div className="fondo_taiwan" style={{backgroundImage: 'url(img/taiwan/lineas.png)'}}>
              <div className="titulo_hidalgo">HIDALGO,</div>
              <div className="sub_hidalgo">
                {
                  this.state.lenguaje==='IN' ? 
                    window._my_taiwan_titulo_in
                  : 
                    window._my_taiwan_titulo
                }
              </div>
              {
                window._my_taiwan.length>0 ?
                  <div className="flex_taiwan">
                    {
                      window._my_taiwan.map((item, i) =>
                        <a href={item.link} target="_blank" className="de_flex_taiwan" key={i}>
                          <img src={item.ico} alt={item.titleIn}/>
                          <div>{this.state.lenguaje==='IN' ? item.titleIn : item.title}</div>
                        </a>
                      )
                    }
                  </div>
                :
                  null
              }
            </div>
            */}
            <div className="new_taiwan fondo_taiwan" style={{backgroundImage: 'url(img/taiwan/lineas.png)'}}>
              <div className="iconos_taiwan">
                {
                  window._my_taiwan.slice(0, 4).map((item, i) =>
                    <a href={item.link} target="_blank" className="de_icono_taiwan" key={i}>
                      <img src={item.ico} alt={item.titleIn}/>
                      <div>{this.state.lenguaje==='IN' ? item.titleIn : item.title}</div>
                    </a>
                  )
                }
              </div>
              <img src="img/taiwan/circulo.png" alt="México-Taiwan" className="circulo_taiwan"/>
              <div className="iconos_taiwan">
                {
                  window._my_taiwan.slice(4, 8).map((item, i) =>
                    <a href={item.link} target="_blank" className="de_icono_taiwan" key={i}>
                      <img src={item.ico} alt={item.titleIn}/>
                      <div>{this.state.lenguaje==='IN' ? item.titleIn : item.title}</div>
                    </a>
                  )
                }
              </div>
            </div>
            {/*
            <div className="degradados4 pa_degradados" data-aos="fade-right">
              <a href={localStorage.idioma ? "http://mapa.investhidalgo.mx/index.html" : "http://mapa.investhidalgo.mx/index.html?idioma=es"} target="new" className="texto_calidad">
                <h5>
                  {
                    localStorage.idioma ? 
                      "ECONOMIC MAP" 
                    : 
                      "MAPA ECONÓMICO"
                  }
                </h5>
              </a>
              <div id="cl"><br/></div>
              {localStorage.idioma ? "State, municipal and regional geo-referenced map." : "Mapa geo-referenciado del estado, municipal y regionalmente."}
              <div id="cl"><br/></div>
              <a href={localStorage.idioma ? "http://mapa.investhidalgo.mx/index.html" : "http://mapa.investhidalgo.mx/index.html?idioma=es"} target="new" className="texto_calidad">
                <h55>{localStorage.idioma ? "GO TO MAP" : "IR AL MAPA"}</h55>
              </a>
              <div id="cl"></div>
              <div className="ver_mas" onClick={this.handleShow.bind(this, "INTRO")}>+</div>
            </div>
            <div className="flexMapa">
              {
                window._my_mapaInformacion.length>0 ?
                  window._my_mapaInformacion.map((item, i) => {
                    let color = ""
                    if(i===1 || i===3){
                      color = "rgba(43, 45, 60, 0.6)"
                    }
                    return(
                      <div onClick={this.handleShow.bind(this, item.botonIn)} style={{backgroundColor: color}}>{localStorage.idioma ? item.botonIn : item.boton}</div>
                    )
                  })
                :
                  null
              }
            </div>
            */}
              
          </div>

          {/*<Modal
            show={this.state.show}
            onHide={this.handleHide.bind(this)}
            dialogClassName="modal-90w"
            className="modal_fer"
            aria-labelledby="example-custom-modal-styling-title">

            <Modal.Body className="modal_cuadro" data-aos="fade-down">
              {
                this.state.cual==='INTRO' ?
                  <div className="c_infra">
                      <div className="cc_infra">
                        <div className="degradadosx padding_infra">
                          <h51>{localStorage.idioma ? "INTRODUCTION" : "INTRODUCCIÓN"}</h51>
                          <br/><br/>
                          <div dangerouslySetInnerHTML={{ __html: localStorage.idioma ? window._my_introduccionIn : window._my_introduccion }} className="texto_infra at_j"/>
                          <img src="img/h_naranja.svg" alt="Investh Hidalgo" className="invertir_icono"/>
                          <div id="cl"></div>
                          <h21>HIDALGO: THE MEXICAN SPOT</h21>
                        </div>
                      </div>
                    </div>
                :
                  window._my_mapaInformacion.filter(p => p.botonIn===this.state.cual).map((item, i) =>
                    <div className="c_infra">
                      <div className="cc_infra">
                        <div className="degradadosx padding_infra">
                          <img src={item.imagen} title={localStorage.idioma ? item.tituloIn : item.titulo} alt={localStorage.idioma ? item.tituloIn : item.titulo}/>
                          <div id="cl"></div>
                          <h51>{localStorage.idioma ? item.tituloIn : item.titulo}</h51>
                          <br/><br/>
                          <div dangerouslySetInnerHTML={{ __html: localStorage.idioma ? item.descripcionIn : item.descripcion }} className="texto_infra at_j"/>
                          <img src="img/h_naranja.svg" alt="Investh Hidalgo" className="invertir_icono"/>
                          <div id="cl"></div>
                          <h21>HIDALGO: THE MEXICAN SPOT</h21>
                        </div>
                      </div>
                    </div>
                  )
              }
            </Modal.Body>
          </Modal>*/}
        </div>
      )
    
  }
}

export default TaiwanVisit;