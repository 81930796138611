import React, { Component } from 'react';
import Menu from './Menu'
import Redes from './Redes'
import Logo from './Logo'
import Pie from './Pie'

import FontAwesome from 'react-fontawesome'

import { Link as Ir, Element } from 'react-scroll';

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

class Cali extends Component {
  componentWillMount () {
    window.H5_loading.show();
    window.scrollTo(0, 0)
  }
  
  componentDidMount () {
    window.H5_loading.hide();
  }

  render () {
    return (
      <div>
        <Menu/>
        <Logo/>
        <Redes/>
        <div className="fondo_cali">
          <div className="max_width padding_width contenido_infra">
            <center>
              <div className="cuadro_cali">
                <div className="flex_infra">
                  {
                    window._my_cali.filter(p => p.tipo===1).map((item, i) =>
                      <Ir to={item.ir} activeClass="active" spy={true} smooth={true} duration={500} className="de_flex_infra" key={i}>
                        <img src={item.ico} title={localStorage.idioma ? item.nombreIn : item.nombre} alt={localStorage.idioma ? item.nombreIn : item.nombre} className="ico_infra grises"/>
                        <div id="cl"></div>
                        {localStorage.idioma ? item.nombreIn : item.nombre}
                      </Ir>
                    )
                  }
                </div>
                <div id="cl"><br/><br/></div>
                <h23>{localStorage.idioma ? "QUALITY OF LIFE" : "CALIDAD DE VIDA"}</h23>
                <div id="cl"><br/><br/></div>
                <div className="flex_infra">
                  {
                    window._my_cali.filter(p => p.tipo===2).map((item, i) =>
                      <Ir to={item.ir} activeClass="active" spy={true} smooth={true} duration={500} className="de_flex_infra" key={i}>
                        <img src={item.ico} title={localStorage.idioma ? item.nombreIn : item.nombre} alt={localStorage.idioma ? item.nombreIn : item.nombre} className="ico_infra grises"/>
                        <div id="cl"></div>
                        {localStorage.idioma ? item.nombreIn : item.nombre}
                      </Ir>
                    )
                  }
                </div>
              </div>
            </center>
            {
              window._my_cali.map((item, i) =>
                <div key={i}>
                  <div id="cl"></div>
                  <Element name={item.ir} id="cl_hh"></Element>
                  <div className="c_infra">
                    <div className="cc_infra" style={{backgroundImage:"url('"+item.foto+"')"}}>
                      <div className="degradadosx padding_infra">
                        <img src={item.ico} title={localStorage.idioma ? item.nombreIn : item.nombre} alt={localStorage.idioma ? item.nombreIn : item.nombre} data-aos="zoom-in"/>
                        <div id="cl"></div>
                        <h51>{localStorage.idioma ? item.nombreIn : item.nombre}</h51>
                        <br/><br/>
                        <div dangerouslySetInnerHTML={{ __html: localStorage.idioma ? item.descripcionIn : item.descripcion }} className="texto_infra at_j" data-aos="fade-down"/>
                        <img src="img/h_naranja.svg" alt="Investh Hidalgo" className="invertir_icono"/>
                        <div id="cl"></div>
                        {/*<h21>HIDALGO: THE MEXICAN SPOT</h21>*/}
                        <div id="cl"></div>
                        <Ir to="inicio" activeClass="active" spy={true} smooth={true} duration={500}>
                          <center>
                            <FontAwesome
                              className="flecha_infra"
                              name="angle-double-up"
                              data-aos="fade-down"
                              data-aos-easing="linear"
                              data-aos-duration="800"
                              //spin
                            />
                          </center>
                        </Ir>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        </div>
        <div id="cl"></div>
        <Pie />
      </div>
    )
  }
}

export default Cali;