import React, { Component } from 'react';
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'

import Modal from 'react-bootstrap/Modal';

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

class Sectores extends Component {
  componentWillMount () {
    window.H5_loading.show();
  }
  
  componentDidMount () {
    window.H5_loading.hide();
  }

  responsive = {
    0: { items: 1 },
    500: { items: 2 },
    700: { items: 3 },
    900: { items: 4 },
    1100: { items: 5 },
  }

  items = window._my_sectores
 
  state = {
    show: false,
    sector: 1,
    id: "",

    galleryItems: this.items.filter(p => p.sector === 1).map((item, i) => 
      <div key={i} className="carousel_estilo">
        <img src={item.foto} title={item.nombre} alt={item.nombre} className="carousel_sectores" onClick={this.handleShow.bind(this, item.nombre)}/>
        <div id="cl"><br/></div>
        <h49>{item.nombre}</h49>
        <div id="cl"></div>
        <div className="ver_mas" onClick={this.handleShow.bind(this, item.nombre)}>+</div>
      </div>
    ),
    galleryItemsIn: this.items.filter(p => p.sector === 1).map((item, i) => 
      <div key={i} className="carousel_estilo">
        <img src={item.foto} title={item.nombreIn} alt={item.nombreIn} className="carousel_sectores" onClick={this.handleShow.bind(this, item.nombre)}/>
        <div id="cl"><br/></div>
        <h49>{item.nombreIn}</h49>
        <div id="cl"></div>
        <div className="ver_mas" onClick={this.handleShow.bind(this, item.nombre)}>+</div>
      </div>
    ),
    galleryItems2: this.items.filter(p => p.sector === 2).map((item, i) => 
      <div key={i} className="carousel_estilo">
        <img src={item.foto} title={item.nombre} alt={item.nombre} className="carousel_sectores" onClick={this.handleShow.bind(this, item.nombre)}/>
        <div id="cl"><br/></div>
        <h49>{item.nombre}</h49>
        <div id="cl"></div>
        <div className="ver_mas" onClick={this.handleShow.bind(this, item.nombre)}>+</div>
      </div>
    ),
    galleryItemsIn2: this.items.filter(p => p.sector === 2).map((item, i) => 
      <div key={i} className="carousel_estilo">
        <img src={item.foto} title={item.nombreIn} alt={item.nombreIn} className="carousel_sectores" onClick={this.handleShow.bind(this, item.nombre)}/>
        <div id="cl"><br/></div>
        <h49>{item.nombreIn}</h49>
        <div id="cl"></div>
        <div className="ver_mas" onClick={this.handleShow.bind(this, item.nombre)}>+</div>
      </div>
    ),
  }

  sectorShow(e){
    window.H5_loading.show();
    this.setState({ sector: e });
    window.H5_loading.hide();
  };

  handleShow(id, e){
    window.H5_loading.show();
    this.setState({ show: true, id: id });
    window.H5_loading.hide();
  };

  handleHide(){
    this.setState({ show: false });
  };

  render () {
      return (
        <div className="fondo_sectores">
          {
            window._my_sectores.length > 0 ?
              <div className="max_width padding_width">
                <center>
                  {/*
                  <h18>HIDALGO: THE MEXICAN SPOT</h18>
                  <br/><br/>
                  */}
                  <h16><h5>{localStorage.idioma ? "STRATEGIC SECTORS" : "SECTORES ESTRATÉGICOS"}</h5></h16>
                </center>
                <div id="cl"><br/></div>
                <div className="pa_flex_2">
                  <div className="de_flex_2 degradados4 de_sectores dd_sectores_1" data-aos="fade-right" onClick={this.sectorShow.bind(this, 1)}>
                    <h24>{this.state.sector===1 ? "•" : null}</h24> {localStorage.idioma ? "CONSOLIDATED" : "CONSOLIDADOS"}
                  </div>
                  <div className="de_flex_2 degradados3 de_sectores dd_sectores_2" data-aos="fade-left" id={this.state.sector===2 ? "de_bordeh" : "de_borde"} onClick={this.sectorShow.bind(this, 2)}>
                    <h24>{this.state.sector===2 ? "•" : null}</h24> {localStorage.idioma ? "DEVELOPING SECTORS" : "EN DESARROLLO"}
                  </div>
                </div>
                <div id="cl"><br/><br/></div>
                <AliceCarousel
                  responsive={this.responsive}
                  autoPlayInterval={3000}
                  autoPlayDirection="rtl"
                  autoPlay={true}
                  fadeOutAnimation={true}
                  mouseTrackingEnabtutoled={false}
                  playButtonEnabled={false}
                  disableAutoPlayOnAction={true}
                  dotsDisabled={false}
                  buttonsDisabled={true}
                  items={
                    localStorage.idioma ? 
                      this.state.sector===1 ?
                        this.state.galleryItemsIn 
                      :
                        this.state.galleryItemsIn2
                    : 
                      this.state.sector===1 ?
                        this.state.galleryItems 
                      :
                        this.state.galleryItems2
                  }
                  ref={(el) => (this.Carousel = el)}
                />
              </div>
            :
              null
          }

          <Modal
            show={this.state.show}
            onHide={this.handleHide.bind(this)}
            dialogClassName="modal-90w"
            className="modal_fer"
            aria-labelledby="example-custom-modal-styling-title">

            <Modal.Body className="modal_cuadro cuadro_sectores" data-aos="fade-down">
              <div className="cuadro_mundo">
                <div className="jh-close close-button" onClick={this.handleHide.bind(this)}>x</div>
                {
                  this.state.id ?
                    window._my_sectores.filter(p => p.nombre === this.state.id).slice(0, 1).map((item, i) =>  
                      <div className="sectores" data-aos="fade-right" key={i}>
                        <div className="flex_sectores">
                          <div className="descripcion_sectores">
                            {
                              localStorage.idioma ?
                                <div>
                                  <h19>{item.tipoIn}</h19>
                                  <br/>SECTOR<br/><br/>
                                </div>
                              :
                                <div>
                                  SECTOR<br/>
                                  <h19>{item.tipo}</h19>
                                  <br/><br/>
                                </div>
                            }
                            <h100><h20>{localStorage.idioma ? item.nombreIn : item.nombre}</h20></h100>
                            <br/><br/>
                            <div dangerouslySetInnerHTML={{ __html: localStorage.idioma ? item.descripcionIn : item.descripcion }} />
                            <div className="linea_horizontal"></div>
                          </div>
                          <div className="foto_sectores">
                            <img src={`${item.foto}`} title={localStorage.idioma ? item.nombreIn : item.nombre} alt={localStorage.idioma ? item.nombreIn : item.nombre}/>
                          </div>
                        </div>
                      </div>
                    )
                  :
                    null
                }
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )
    
  }
}

export default Sectores;