import { ES, IN } from './actionTypes';

const stateIni = {
	lenguaje: ES
}

const reducerIdiomas = (state = stateIni, action) => {
	switch (action.type) {
    case 'ES':
      return ({lenguaje: ES})
    case 'IN':
      return ({lenguaje: IN})
    default:
      return state
  }
}

export default reducerIdiomas;