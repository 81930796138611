import React, { Component } from 'react';

import Modal from 'react-bootstrap/Modal';

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

class Ubicacion extends Component {
  componentWillMount () {
    window.H5_loading.show();
  }
  
  componentDidMount () {
    window.H5_loading.hide();
  }

  state = {
    show: false,
  }

  handleShow(){
    window.H5_loading.show();
    this.setState({ show: true });
    window.H5_loading.hide();
  };

  handleHide(){
    this.setState({ show: false });
  };

  render () {
      return (
        <div className="fondo_ubicacion">
          <div className="max_width padding_width at_c">
              
            <div className="degradados4 pa_degradados" data-aos="fade-right">
              <button to="calidad" className="texto_calidad_c" onClick={this.handleShow.bind(this)}>
                {
                  localStorage.idioma ? 
                    "PRIVILEGED LOCATION IN THE HEART OF MEXICO" 
                  : 
                    "PRIVILEGIADA LOCALIZACIÓN EN EL CORAZÓN DE MÉXICO"
                }
              </button>
              <div id="cl"><br/></div>
              <div to="calidad" className="texto_calidad" onClick={this.handleShow.bind(this)}>
                <h5>{localStorage.idioma ? "LOCATION" : "UBICACIÓN"}</h5>
              </div>
              <div id="cl"></div>
              <div className="ver_mas" onClick={this.handleShow.bind(this)}>+</div>
            </div>
              
          </div>

          <Modal
            show={this.state.show}
            onHide={this.handleHide.bind(this)}
            dialogClassName="modal-90w"
            className="modal_fer"
            aria-labelledby="example-custom-modal-styling-title">

            <Modal.Body className="modal_cuadro" data-aos="fade-down">
              {
                window._my_ubicacion.map((item, i) =>
                  <div className="cuadro_ubica" style={{backgroundImage:"url('"+item.fondo+"')"}}>
                    <div className="de_cuadro_parques">
                      <div className="jh-close close-button" onClick={this.handleHide.bind(this)}>x</div>
                      <div id="cl"><br/></div>
                      <div className="flex_ubica">
                        <div className="de_flex_ubica">
                          <h100><h6>{localStorage.idioma ? item.nombreIn : item.nombre}</h6></h100>
                          <br/><br/>
                          <div dangerouslySetInnerHTML={{ __html: localStorage.idioma ? item.descripcionIn : item.descripcion }} className="texto_ubica"/>
                        </div>
                        <div className="de_flex_ubica_img">
                          <div className="t_ubica degradados">{localStorage.idioma ? item.subIn : item.sub}</div>
                          <div className="img_ubica" id={item.ubica} style={{backgroundImage:"url('"+item.foto+"')"}}></div>
                          <div id="cl"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            </Modal.Body>
          </Modal>
        </div>
      )
    
  }
}

export default Ubicacion;