import React, { Component } from 'react';
import { Link } from 'react-router-dom'

class Logo extends Component {
  render () {
    return (
      <div>
        <Link to="../inicio"><img src={`${window.rutas}img/logo_investh.svg`} className={this.props.cual ? "logo grises" : "logo2 grises"} alt="Invest Hidalgo" title="Invest Hidalgo"/></Link>
        <div className="texto_menu">
          {localStorage.idioma ? "MENU" : "MENÚ"}
        </div>
      </div>
    )
  }
}

export default Logo;