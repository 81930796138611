import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Element, Link as Ir, scroller } from 'react-scroll';
import { Accordion, AccordionItem } from 'react-sanfona';

//import Es from './../redux/actionEs';
//import In from './../redux/actionIn';

import { connect } from 'react-redux';
import { elastic as MenuBurger } from 'react-burger-menu'

//var urlActual2 = ""
//var cadena2 = ""

/*
var lol="asd";
window.onscroll = function (){
    // Obtenemos la posicion del scroll en pantall
    var scroll = document.documentElement.scrollTop || document.body.scrollTop;

    // Realizamos alguna accion cuando el scroll este entre la posicion 300 y 400
    if(scroll > 300 && scroll < 400){
        console.log("Pasaste la posicion 300 del scroll");
        lol="lolaso"
    }
}
*/

class Menu extends Component {
  constructor() {
    super();
    this.state = {
      lenguaje: 'ES',
    }
  }

  componentWillMount () {
    //urlActual2 = String(window.location);
    //cadena2 = urlActual2.split("/")
    //alert(cadena2[3])
  }

  componentDidMount () {
    scroller.scrollTo(localStorage.irir, {
      duration: 1500,
      smooth: true,
      spy:true,
    })
    localStorage.removeItem('irir')
  }
/*
  cambiaIdioma (e) {
    this.setState({lenguaje: e})
    if(e==="IN"){
      localStorage.setItem('idioma', e)
      this.props.cambiaIn()
    }else{
      localStorage.removeItem('idioma')
      this.props.cambiaEs()
    }
  }
*/
  cambiaIr (e) {
    localStorage.setItem('irir', e)
  }

  render () {
    return (
      <div>
        <Element name="inicio"></Element>
        <MenuBurger  right customBurgerIcon={<img src={`${window.rutas}img/menu.svg`} alt="MENU"/>}>
          <Link to="../inicio"><center><img src={`${window.rutas}img/h_naranja.svg`} className="logo_menu grises" alt="Invest Hidalgo" title="Invest Hidalgo"/></center></Link>
          <ul className="lista_menu">
            {
              window._my_menu.map((item, i) =>
                <li key={i}>
                  {
                    item.tipo ?
                      item.tipo===1 ?
                        <Accordion allowMultiple={false}>
                          <AccordionItem title={`+ ${localStorage.idioma ? item.titleIn : item.title}`} className="dt">
                            {
                              item.sub.length > 0 ?
                                item.sub.map((p, n) =>
                                  <div>
                                    <div id="cl"><br/></div>
                                    {/*<Link to={{pathname: item.link, data: p.video}} className="link">{localStorage.idioma ? p.titleIn : p.title}</Link>*/}
                                    <Link to={`../${item.link}/${p.enlace}`} className="link">{localStorage.idioma ? p.titleIn : p.title}</Link>
                                  </div>
                                )
                              :
                                null
                            }
                          </AccordionItem>
                        </Accordion>
                      :
                        <Accordion allowMultiple={false}>
                          <AccordionItem title={`+ ${item.title}`} className="dt">
                            {
                              item.sub.length > 0 ?
                                item.sub.map((p, n) =>
                                  <div>
                                    <div id="cl"><br/></div>
                                    <a href={p.enlace} className="link" target="new">{localStorage.idioma ? p.titleIn : p.title}</a>
                                  </div>
                                )
                              :
                                null
                            }
                          </AccordionItem>
                        </Accordion>
                    :
                      this.props.cual && item.ir ?
                        <Ir to={item.ir} activeClass="active" spy={true} smooth={true} duration={1000}>{this.props.ver===1 && this.props.ver==="IN" ? item.titleIn : this.props.ver===1 && this.props.ver==="ES" ? item.title : localStorage.idioma ? item.titleIn : item.title}</Ir>
                      :
                        <Link to={`../${item.link}`} className="link" onClick={this.cambiaIr.bind(this, item.ir)}>{localStorage.idioma ? item.titleIn : item.title}</Link>
                  }
                </li>
              )
            }
          </ul>
          <div className="espacio_menu">
            Camino Real de la Plata No. 305<br/>
            Zona Plateada, Pachuca de Soto, Hidalgo.<br/>
            {window.telefono}
          </div>
          <div id="cl"></div>
          <div className="redes_menu">
            {
              window._my_redes.map((item, i) => 
                <a href={item.link} target="_new" className="de_flex_redes_menu grises" key={i}><img src={window.rutas+item.imagen} alt={item.nombre} title={item.nombre}/></a>
              )
            }
          </div>
        </MenuBurger>
        {/*<div style={{position:"fixed",zIndex:5,bottom:10,right:10}}><img src="img/logo.svg" className="idiomas" title="English" alt="English" onClick={this.cambiaIdioma.bind(this, "IN")}/><img src="img/logo.svg" className="idiomas grises" title="Español" alt="Español" onClick={this.cambiaIdioma.bind(this, "ES")}/></div>*/}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    lenguaje: state.lenguaje
  }
};
/*
const mapDispatchToProps = (dispatch) => {
  return {
    cambiaEs: () => dispatch(Es()),
    cambiaIn: () => dispatch(In()),
  }
}
*/
const Liga = Menu;
export default connect(mapStateToProps)(Liga);
//export default connect(mapStateToProps,mapDispatchToProps)(Liga);