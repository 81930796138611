import React, { Component } from 'react';
import Menu from './Menu'
import Redes from './Redes'
import Logo from './Logo'
import Pie from './Pie'

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

class Video extends Component {
  componentWillMount () {
    window.H5_loading.show();
    window.scrollTo(0, 0)
  }
  
  componentDidMount () {
    window.H5_loading.hide();
  }

  render () {
    //const { data } = this.props.location
    return (
      <div>
        <Menu/>
        <Logo/>
        <Redes/>
        {
          window._my_menu.filter(p => p.tipo === 1).map(h =>
            h.sub.filter(t => t.enlace === this.props.match.params.id).slice(0, 1).map((item, i) => 
              <video autoplay="autoplay" loop="loop" className="video_background" controls key={i} src={
                  localStorage.idioma ? item.videoIn : item.video} type="video/mp4" preload="auto"/>
            )
          )
        }
        <div id="cl"></div>
        <Pie />
      </div>
    )
  }
}

export default Video;