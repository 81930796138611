import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Modal from 'react-bootstrap/Modal';

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

class Infraestructura extends Component {
  componentWillMount () {
    window.H5_loading.show();
  }
  
  componentDidMount () {
    window.H5_loading.hide();
  }

  state = {
    show: false,
  }

  handleShow(){
    window.H5_loading.show();
    this.setState({ show: true });
    window.H5_loading.hide();
  };

  handleHide(){
    this.setState({ show: false });
  };

  render () {
      return (
        <div className="fondo_infraestructura">
          <div className="max_width padding_width">
            <div className="pa_flex_2">
              <Link to="infraestructura" className="de_flex_infraestrutura degradados2" data-aos="fade-right">
                <div className="de_flex_infraestrutura_img img_infraestructura">
                  <div className="texto_infraestructura degradados2">
                    {localStorage.idioma ? "INDUSTRIAL INFRASTRUCTURE" : "INFRAESTRUCTURA INDUSTRIAL"}
                    <div id="cl"></div>
                    <div className="ver_mas">+</div>
                  </div>
                </div>
              </Link>
              <div className="de_flex_infraestrutura degradados2" data-aos="fade-left">
                <div className="de_flex_infraestrutura_img img_parques" onClick={this.handleShow.bind(this)}>
                  <div className="texto_infraestructura degradados2">
                    {localStorage.idioma ? "INDUSTRIAL PARKS" : "PARQUES INDUSTRIALES"}
                    <div id="cl"></div>
                    <div className="ver_mas">+</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal
            show={this.state.show}
            onHide={this.handleHide.bind(this)}
            dialogClassName="modal-90w"
            className="modal_fer"
            aria-labelledby="example-custom-modal-styling-title">

            <Modal.Body className="modal_cuadro cuadro_parques" data-aos="fade-down">
              <div className="de_cuadro_parques">
                <div className="jh-close close-button" onClick={this.handleHide.bind(this)}>x</div>
                <div id="cl"><br/></div>

                <div className="persona" data-aos="fade-right">
                  <div className="flex_parques">
                    <div className="descripcion_parques">
                      <h66>12</h66>
                      <br/>
                      <h100><h6>{localStorage.idioma ? "INDUSTRIAL PARKS" : "PARQUES INDUSTRIALES"}</h6></h100>
                      <br/>
                      <operacion>{localStorage.idioma ? "In operation" : "En operación"}</operacion>
                      <div className="lista_par3">
                        <div className="de_par3"><div>1</div>Tula</div>
                        <div className="de_par3"><div>2</div>Atitalaquia</div>
                        <div className="de_par3"><div>3</div>QUMA</div>
                        <div className="de_par3"><div>4</div>Tepeji del Río</div>
                        <div className="de_par3"><div>5</div>Tepeji Park</div>
                        <div className="de_par3"><div>6</div>Tizayuca</div>
                        <div className="de_par3"><div>7</div>PLATAH</div>
                        <div className="de_par3"><div>8</div>Metropolitano</div>
                        <div className="de_par3"><div>9</div>La Reforma</div>
                        <div className="de_par3"><div>10</div>Sahagún</div>
                        <div className="de_par3"><div>11</div>MPyMES Sahagún</div>
                        <div className="de_par3"><div>12</div>Desarrollo industrial del Altiplano</div>
                      </div>
                      <div className="linea_100"></div>
                      <div className="flex_aereopuertos">
                        <img src="img/avion.svg" alt="Aereopuerto"/>
                        {localStorage.idioma ? "Felipe Ángeles Intenational Airport" : "Aereopuerto Internacional Felipe Ángeles"}
                      </div>
                      <div className="flex_aereopuertos">
                        <img className="avion_azul" src="img/avion.svg" alt="Aereopuerto"/>
                        {localStorage.idioma ? "México City Intenational Airport" : "Aereopuerto Internacional de la CDMX"}
                      </div>
                      {/*
                      <div id="cl"></div>
                      <div className="flex_number">
                        <div className="pc_number nn_2">
                          2
                        </div>
                        <div className="number_texto tt_2">{localStorage.idioma ? "UNDER DEVELOPMENT" : "EN DESARROLLO"}</div>
                      </div>
                      <ol className="lista_par2">
                        <li>SANTIAGO TULANCINGO</li>
                        <li>ZONA DE ACTIVIDADES LOGÍSTICAS DE HIDALGO (ZALH) – ATOTONILCO DE TULA</li>
                      </ol>
                      */}

                    </div>
                    <div className="foto_parques">
                      <a href="img/parques.png" target="new">
                        <img src="img/parques.png" title={localStorage.idioma ? "INDUSTRIAL PARKS" : "PARQUES INDUSTRIALES"} alt={localStorage.idioma ? "INDUSTRIAL PARKS" : "PARQUES INDUSTRIALES"}/>
                      </a>
                    </div>
                  </div>

                  <div className="linea_100"></div>

                  <div className="madre_pc_flex">
                    <div className="flex_pc">
                      <div className="pcx pc pc_1">
                        <div className="pc_f pc_1"></div>
                      </div>
                      <div className="pc_texto">{localStorage.idioma ? "HIGHWAYS" : "AUTOPISTAS"}</div>
                    </div>
                    <div className="flex_pc">
                      <div className="pcx pc2">
                        <div className="pcz pc_f2"></div>
                      </div>
                      <div className="pc_texto">{localStorage.idioma ? "ARCO NORTE HIGHWAY" : "AUTOPISTA ARCO NORTE"}</div>
                    </div>
                    <div className="flex_pc">
                      <div className="pcx pc3">
                        <div className="pcz pc_f3"></div>
                      </div>
                      <div className="pc_texto">{localStorage.idioma ? "PERIPHERAL HIGHWAY" : "CIRCUITO MEXIQUENSE"}</div>
                    </div>
                  </div>
                  <div className="madre_pc_flex">
                    <div className="flex_pc">
                      <div className="pcx pc4">
                        <div className="pcz pc_f4"></div>
                      </div>
                      <div className="pc_texto">{localStorage.idioma ? "MÉXICO - QUERÉTARO HIGHWAY" : "AUTOPISTA MÉXICO - QUERÉTARO"}</div>
                    </div>
                    <div className="flex_pc">
                      <div className="pcx pc5">
                        <div className="pcz pc_f5"></div>
                      </div>
                      <div className="pc_texto">{localStorage.idioma ? "MÉXICO - PACHUCA HIGHWAY" : "AUTOPISTA MÉXICO - PACHUCA"}</div>
                    </div>
                    <div className="flex_pc">
                      <div className="pcx pc6">
                        <div className="pcz pc_f6"></div>
                      </div>
                      <div className="pc_texto">{localStorage.idioma ? "MÉXICO - TUXPAN HIGHWAY" : "AUTOPISTA MÉXICO - TUXPAN"}</div>
                    </div>
                  </div>

                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )
    
  }
}

export default Infraestructura;