import React, { Component } from 'react';
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'

import Modal from 'react-bootstrap/Modal';

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

class Personal extends Component {
  componentWillMount () {
    window.H5_loading.show();
  }
  
  componentDidMount () {
    window.H5_loading.hide();
  }

  responsive = {
    0: { items: 1 },
    600: { items: 2 },
    1086: { items: 3 },
    //1200: { items: 4 },
  }

  items = window._my_personal
 
  state = {
    show: false,
    id: "",

    galleryItems: this.items.map((item, i) => {
      var sNombre = item.nombre.replace('<br/>', ' ');
      return(
        <div key={i} className="carousel_estilo">
          <img src={item.foto} title={sNombre} alt={sNombre} className="carousel_tik" onClick={this.handleShow.bind(this, item.nombre)}/>
          <div id="cl"><br/></div>
          <h50 dangerouslySetInnerHTML={{ __html: item.nombre }}/>
          <div id="cl"></div>
          <div id="cl_h"></div>
          <div dangerouslySetInnerHTML={{ __html: item.cargo }}/>
          <div className="ver_mas" onClick={this.handleShow.bind(this, item.nombre)}>+</div>
        </div>
      )
    }),
    galleryItemsIn: this.items.map((item, i) => {
      var sNombre = item.nombre.replace('<br/>', ' ');
      return(
        <div key={i} className="carousel_estilo">
          <img src={item.foto} title={sNombre} alt={sNombre} className="carousel_tik" onClick={this.handleShow.bind(this, item.nombre)}/>
          <div id="cl"><br/></div>
          <h50 dangerouslySetInnerHTML={{ __html: item.nombre }}/>
          <div id="cl"></div>
          <div id="cl_h"></div>
          <div dangerouslySetInnerHTML={{ __html: item.cargoIn }}/>
          <div className="ver_mas" onClick={this.handleShow.bind(this, item.nombre)}>+</div>
        </div>
      )
    }),
  }
  /*
  thumbItem = (item, i) => (
    <span key={item} onClick={() => this.Carousel.slideTo(i)}>
      *{' '}
    </span>
  )
  */

  handleShow(id, e){
    window.H5_loading.show();
    this.setState({ show: true, id: id });
    window.H5_loading.hide();
  };

  handleHide(){
    this.setState({ show: false });
  };

  render () {
      return (
        <div className="fondo_personal">
          {
            window._my_personal.length > 0 ?
              <div className="max_width padding_width">
                <center><h16><h5>{localStorage.idioma ? "ABOUT US" : "¿QUIÉNES SOMOS?"}</h5></h16></center>
                <div id="cl"><br/><br/></div>
                <AliceCarousel
                  responsive={this.responsive}
                  autoPlayInterval={3000}
                  autoPlayDirection="rtl"
                  autoPlay={true}
                  fadeOutAnimation={true}
                  mouseTrackingEnabtutoled={false}
                  playButtonEnabled={false}
                  disableAutoPlayOnAction={true}
                  dotsDisabled={false}
                  buttonsDisabled={true}
                  items={localStorage.idioma ? this.state.galleryItemsIn : this.state.galleryItems}
                  ref={(el) => (this.Carousel = el)}
                />
              </div>
            :
              null
          }

          <Modal
            show={this.state.show}
            onHide={this.handleHide.bind(this)}
            dialogClassName="modal-90w"
            className="modal_fer"
            aria-labelledby="example-custom-modal-styling-title">

            <Modal.Body className="modal_cuadro cuadro_personal" data-aos="fade-down">
              <div className="jh-close close-button" onClick={this.handleHide.bind(this)}>x</div>
              <div id="cl"><br/><br/></div>
              {
                this.state.id ?
                  window._my_personal.filter(p => p.nombre === this.state.id).slice(0, 1).map((item, i) => {
                    var sNombre = item.nombre.replace('<br/>', ' ');
                    var sCargo = item.cargo.replace('<br/>', ' ');
                    var sCargoIn = item.cargoIn.replace('<br/>', ' ');
                    return(
                      <div className="persona" data-aos="fade-right" key={i}>
                        <div className="flex_persona">
                          <div className="foto_persona">
                            <div className="linea_persona"></div>
                            <img src={`${item.foto}`} title={sNombre} alt={sNombre}/>
                            <div className="linea_persona"></div>
                          </div>
                          <div className="descripcion_persona">
                            <h100><h6>{sNombre}</h6></h100>
                            <h100><b>{localStorage.idioma ? sCargoIn : sCargo}</b></h100>
                            <div id="cl"><br/></div>
                            {
                              item.linkedln ?
                                <a href={item.linkedln} target="_new" className="f_r grises"><img src="img/r_in-01.svg" alt="Twitter" title="Twitter"/></a>
                              :
                                null
                            }
                            {
                              item.twitter ?
                                <a href={item.twitter} target="_new" className="f_r grises"><img src="img/r_twi-01.svg" alt="Twitter" title="Twitter"/></a>
                              :
                                null
                            }
                            {
                              item.facebook ?
                                <a href={item.facebook} target="_new" className="f_r grises"><img src="img/r_face-01.svg" alt="LinkedIn" title="LinkedIn"/></a>
                              :
                                null
                            }
                            { item.linkedln || item.twitter || item.facebook ? <div id="cl"><br/></div> : null }
                            <div className="at_j" dangerouslySetInnerHTML={{ __html: localStorage.idioma ? item.descripcionIn : item.descripcion }} />
                          </div>
                        </div>
                      </div>
                    )
                  })
                :
                  null
              }
              <img src="img/h_naranja.svg" alt="Investh Hidalgo" className="persona_icono"/>
              <div id="cl"></div>
            </Modal.Body>
          </Modal>
        </div>
      )
    
  }
}

export default Personal;