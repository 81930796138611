import React, { Component } from 'react';

class Menu extends Component {
  render () {
    return (
      <div className="redes_dos">
        {
          window._my_redes.map((item, i) => 
            <div>
              <a href={item.link} target="_new" className="de_flex_redes grises" key={i}><img src={window.rutas+item.imagen} alt={item.nombre} title={item.nombre}/></a>
            </div>
          )
        }
      </div>
    )
  }
}

export default Menu;