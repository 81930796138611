import React, { Component } from 'react';
import Menu from './Menu'
import Logo from './Logo'
import Pie from './Pie'

import Axios from 'axios';

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

class Contacto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nombre:'',
      correo:'',
      pais:'',
      estado:'',
      mensaje:''
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentWillMount () {
    window.H5_loading.show();
    window.scrollTo(0, 0)
  }
  
  componentDidMount () {
    window.H5_loading.hide();
  }

  handleChange(e) {
    const {name, value} = e.target;
    this.setState({[name]:value})
  }

  handleClick(e) {
    e.preventDefault();

    const {nombre, estado, pais, correo, mensaje} = this.state;
    if(nombre===''){
      window.alerta('error', localStorage.idioma ? 'Enter your full name' : 'Ingresa tu nombre completo');
    }else if(estado===''){
      window.alerta('error', localStorage.idioma ? 'Enter your city' : 'Ingresa tu ciudad');
    }else if(pais===''){
      window.alerta('error', localStorage.idioma ? 'Enter your country' : 'Ingresa tu país');
    }else if(correo===''){
      window.alerta('error', localStorage.idioma ? 'Enter your email' : 'Ingresa tu correo electrónico');
    }else if(window.validarMail(correo)===false){
      window.alerta('error', localStorage.idioma ? 'Invalid email' : 'Ingrese un correo electrónico valido');
    }else if(mensaje===''){
      window.alerta('error', localStorage.idioma ? 'Write a message' : 'Escribe un mensaje');
    }else{
      let formData = new FormData(document.getElementById('formulario'));
      Axios.post(window.envia, formData)
      .then(response => {
        if(response.data===3){
          window.alerta('error', localStorage.idioma ? 'Error, try again later' : 'Ha ocurrido un error intentalo más tarde');
        }else{
          if(response.data===2){
            window.alerta('error', localStorage.idioma ? 'A required field is empty' : 'Un campo requerido está vacío');
          }else{
            window.alerta('success', localStorage.idioma ? 'Mail sent successfully' : 'El mensaje ha sido enviado de manera satisfactoria, pronto nos pondremos en contacto');
            this.setState({nombre:'',correo:'',mensaje:'',pais:'',estado:''});
          }
        }
        console.log(response.data)
      })
      .catch(error => {
        //window.alerta('error','LOLERO'+error);
        console.log(error)
      })
      .then(function() {
        window.H5_loading.hide();
      })
    }
  }

  render () {
    return (
      <div>
        {
          this.props.cual ?
            null
          :
            <>
              <Menu/>
              <Logo/>
            </>
        }
        <div className="bg_azulSedeco">
          <div className="max_width padding_contacto">
            {/*<center>
              <h18>HIDALGO: THE MEXICAN SPOT</h18>
            </center>
            <div id="cl"><br/><br/></div>*/}
            {
              this.props.cual ?
                <center>
                  <h18>{localStorage.idioma ? 'Mailbox of complaints and suggestions' : 'Buzón con quejas y sugerencias'}</h18>
                  <div id="cl"><br/><br/></div>
                </center>
              :
                <center>
                  <h18>{localStorage.idioma ? 'CONTACTO' : 'CONTACT'}</h18>
                  <div id="cl"><br/><br/></div>
                </center>
            }
            <div className="pa_flex_2">
              {
                this.props.cual ?
                  null
                :
                  <div className="text_media infcontacto" data-aos="fade-right">
                    <ul className="list1">
                      <li className="telefono">{window.telefono}</li>
                      <li className="correo"><a href={`mailto:${window.correo}`}>{window.correo}</a></li>
                      <li className="ubicacion">{window.ubicacion}</li>
                    </ul>
                    <div className="redes_contacto">
                      {
                        window._my_redes.map((item, i) => 
                          <a href={item.link} target="_new" className="de_flex_contacto grises" key={i}><img src={item.imagen} alt={item.nombre} title={item.nombre}/></a>
                        )
                      }
                    </div>
                  </div>
              }  
              <div className="text_media infoform" data-aos="fade-left">
                <form id="formulario">
                  <input type="text" name="nombre" onChange={this.handleChange} placeholder={localStorage.idioma ? "Name:" : "Nombre:"} value={this.state.nombre} className="degradados3"/>
                  <select name="pais" className="select degradados3" onChange={this.handleChange}>
                    <option value="">{localStorage.idioma ? "Country:" : "País:"}</option>
                    {
                      localStorage.idioma ?
                        window.paisIn.map((item, i) => <option value={item} key={i}>{item}</option>)
                      :
                        window.pais.map((item, i) => <option value={item} key={i}>{item}</option>)
                    }
                  </select>
                  {
                    this.state.pais === "México" ?
                      <select name="estado" className="select degradados3" onChange={this.handleChange}>
                        <option value="">Estado:</option>
                        {window.estados.map((item, i) => <option value={item} key={i}>{item}</option>)}
                      </select>
                    :
                      <input type="text" name="estado" onChange={this.handleChange} placeholder={localStorage.idioma ? "City:" : "Ciudad:"} value={this.state.estado} className="degradados3"/>
                  }
                  <input type="text" name="correo" onChange={this.handleChange} placeholder="Email:" value={this.state.correo} className="degradados3"/>
                  
                  <textarea name="mensaje" id="area" onChange={this.handleChange} placeholder={localStorage.idioma ? "Message:" : "Mensaje:"} value={this.state.mensaje} className="degradados3"/>                  
                  <button onClick={this.handleClick} className="button_c degradados3">{localStorage.idioma ? "Send" : "Enviar"}</button>
                  <div id="cl"></div>
                </form>
              </div>
            </div>
            <div className="linea_degra"></div>
          </div>
        </div>
        {
          this.props.cual ?
            null
          :
            <>
              <iframe src={window.mapa} className="mapa" title="Mapa" allowfullscreen/>
              <div id="cl"></div>
              <Pie />
            </>
        }
      </div>
    )
  }
}

export default Contacto;